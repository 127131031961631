import React from 'react';
import ReactDOM from 'react-dom/client';
import "./bootstrap.min.css";
import './index.css';
import { AXIOS_REQUEST } from './services/axiosService';
import AllScreens from './screens';

declare global {
  interface Window {
    location: Location;
    [x: string]: any;
  }
}

console.log("%cADVERTENCIA: \nEsta es una función del navegador destinada a desarrolladores. \nSi intenta hacer algo aquí para habilitar alguna función o \"piratear\" caracteristicas del sitio, podrías perder el acceso al mismo."
  , "color:red;font-size:20px;background-color: yellow;font-weight: bold;");

{
  !!(window._NGconfig.maintenance_url) && AXIOS_REQUEST(window._NGconfig.maintenance_url)
    .then(resp => {
      if (!!(resp) && typeof resp === 'string') {
        window.location.href = resp;
      }
    }).catch(() => { })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<AllScreens />);
