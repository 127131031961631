// import { Suspense } from 'react';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import FallbackLoader from '../components/UI/FallbackLoader';
// import { Toaster } from 'react-hot-toast';

// import AdminRootScreens from './Admin'
// import UsersRootScreens from './User'

// const AllScreens = () => {
//   return (
//     <BrowserRouter>
//       <Toaster position='top-right' />
//       <Suspense fallback={<FallbackLoader text="Cargando" style={{ "position": "fixed" }} />}>
//         <Routes>
//           <Route path='/admin/*' element={<AdminRootScreens />} />
//           <Route index path='/*' element={<UsersRootScreens />} />
//         </Routes>
//       </Suspense>
//     </BrowserRouter>
//   )
// }

// export default AllScreens;

import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import lazyLoaderComponents from '../services/lazyLoaderComponents';
import FallbackLoader from '../components/UI/FallbackLoader';
import { Toaster } from 'react-hot-toast';

const AdminRootScreens = lazyLoaderComponents(() => import('./Admin'))
const UsersRootScreens = lazyLoaderComponents(() => import('./User'))

const AllScreens = () => {
  return (
    <BrowserRouter>
      <Toaster position='top-right' />
      <Suspense fallback={<FallbackLoader text="Cargando" style={{ "position": "fixed" }} />}>
        <Routes>
          <Route path='/admin/*' element={<AdminRootScreens />} />
          <Route index path='/*' element={<UsersRootScreens />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default AllScreens;
