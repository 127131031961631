import { I_JSONObject } from '../../../interfaces/generic.interface';

const loaderStyle = {
    "height": "100vh",
    "top": "0px",
    "left": "0px",
    "zIndex": 1,
    "backgroundColor": "#2323233d"
};

type T_Props = {
    text: string | null,
    style: I_JSONObject
}

const FallbackLoader = ({ text, style = {} }: T_Props) => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100"
            style={{ ...loaderStyle, ...style }}>
            <div className="spinner-grow" style={{ "width": "3rem", "height": "3rem" }} role="status">
            </div>
            <div><small>{text}</small></div>
        </div>
    );
};

export default FallbackLoader;